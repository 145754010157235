import styles from './SearchAlert.module.scss';
import dynamic from 'next/dynamic';
const IcoWarning = dynamic(() => import('iconos').then(mod => mod.IcoWarning));
export const SearchAlert = ({ children, status = 'error' }) => {
	return <div className={styles.notFoundAlert}>
		{status === 'error' && <IcoWarning />}
		<div className={styles.notFoundAlertText}>
			{children}
		</div>
	</div>;
};