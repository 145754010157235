
export const ImgBuscadorMobile = ({ w = 211, h = 81}) => {
    return (
        <svg width={w} height={h} viewBox="0 0 211 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.3551 50.1061H69.0495L54.2616 35.0725H12.5672L27.3551 50.1061Z" fill="#8593E5" />
            <path d="M12.5674 35.0725L27.497 49.9881V80.9995H0V50.9086L12.5674 35.0725Z" fill="#A5A5E1" />
            <path d="M69.0498 50.1062V80.9995H27.4971V49.9882L69.0498 50.1062Z" fill="#3200C1" />
            <path d="M38.269 60.3021C36.261 60.3021 34.6311 61.9306 34.6311 63.9366V71.8193H41.9069V63.9366C41.9069 61.9306 40.2769 60.3021 38.269 60.3021Z" fill="#CBDBF6" />
            <path d="M58.0648 60.3021C56.0569 60.3021 54.4269 61.9306 54.4269 63.9366V71.8193H61.7028V63.9366C61.7028 61.9306 60.0728 60.3021 58.0648 60.3021Z" fill="#CBDBF6" />
            <path d="M13.1813 59.4283C9.42526 59.4283 6.37791 62.4727 6.37791 66.2253V80.9993H20.0083V66.2253C20.0083 62.4727 16.961 59.4283 13.1813 59.4283Z" fill="#CBDBF6" />
            <path d="M103.893 27.0476H46.0874L72.0253 0.709167H129.854L103.893 27.0476Z" fill="#CBDBF6" />
            <path d="M129.854 0.709167L103.68 26.8352V80.9988H151.894V28.4636L129.854 0.709167Z" fill="#A5A5E1" />
            <path d="M46.0874 27.047V80.9983H103.68V26.8346L46.0874 27.047Z" fill="#8593E5" />
            <path d="M61.4899 57.044C57.3086 57.044 53.9305 60.4189 53.9305 64.5962V80.9987H69.0492V64.5962C69.0492 60.4189 65.6711 57.044 61.4899 57.044Z" fill="#24018A" />
            <path d="M58.6552 34.7643C56.0331 34.7643 53.907 36.8884 53.907 39.5081V49.798H63.3798V39.5081C63.4034 36.8884 61.2774 34.7643 58.6552 34.7643Z" fill="#24018A" />
            <path d="M87.522 34.7643C84.8999 34.7643 82.7738 36.8884 82.7738 39.5081V49.798H92.2702V39.5081C92.2702 36.8884 90.1441 34.7643 87.522 34.7643Z" fill="#24018A" />
            <path d="M120.429 34.835C117.807 34.835 115.681 36.9591 115.681 39.5788V49.8687H125.154V39.5788C125.178 36.9591 123.052 34.835 120.429 34.835Z" fill="#24018A" />
            <path d="M73.0884 34.7643C70.4663 34.7643 68.3403 36.8884 68.3403 39.5081V49.798H77.813V39.5081C77.8366 36.8884 75.7106 34.7643 73.0884 34.7643Z" fill="#24018A" />
            <path d="M170.557 35.5434H134.343L156.195 13.3351H192.409L170.557 35.5434Z" fill="#8593E5" />
            <path d="M192.409 13.3351L170.369 35.3782V80.9983H211V36.7234L192.409 13.3351Z" fill="#3200C1" />
            <path d="M134.343 35.5434V80.9984H170.368V35.3782L134.343 35.5434Z" fill="#CBDBF6" />
            <path d="M187.377 50.1045H178.258V70.9675H187.377V50.1045Z" fill="#CBDBF6" />
            <path d="M203.299 50.1044H194.18V70.9674H203.299V50.1044Z" fill="#CBDBF6" />
            <path d="M157.626 47.0475C158.322 44.1179 156.509 41.1794 153.577 40.4842C150.644 39.7889 147.703 41.6003 147.007 44.5299C146.311 47.4595 148.124 50.3981 151.057 51.0933C153.989 51.7885 156.93 49.9772 157.626 47.0475Z" fill="#8593E5" />
            <path d="M152.439 57.0433C148.257 57.0433 144.879 60.4182 144.879 64.5956V80.9981H159.998V64.5956C159.998 60.4182 156.62 57.0433 152.439 57.0433Z" fill="#8593E5" />
        </svg>
    );
};