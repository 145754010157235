import { useEffect, useRef, useState } from 'react';
import styles from './Buscador.module.scss';
// Prevent multiple request while writing
const DEFAULT_WAIT_TIME = 300;
export const SearchInput = ({
	inputElement,
	items,
	SearchResultRowComponent,
	AutocompleteComponent,
	setSearchResult,
	searchCallback,
	setSelectedValueCallback,
	searchValue }) => {

	const [value, setValue] = useState(searchValue ?? '');
	const [waitingSearchCallback, setWaitingSearchCallback] = useState(false);
	const [didTrySearch, setDidTrySearch] = useState(false);
	const searchTimer = useRef(null);

	const clearTimeoutRefObserver = () => () => {
		searchTimer.current && clearTimeout(searchTimer.current);
		searchTimer.current = null;
	};
	const searchResultsObserver = () => {
		setDidTrySearch(false);
		if (searchTimer.current !== null)
			clearTimeout(searchTimer.current);

		if ((value === null || value === undefined || value.trim() === '') && setSearchResult) {
			setSearchResult([]);
			return;
		}

		searchTimer.current = setTimeout(() => {
			setWaitingSearchCallback(true);
			searchCallback(value)
				.then(items => items !== null && items !== undefined && setSearchResult(items))
				.finally(() => setWaitingSearchCallback(false) || setDidTrySearch(true));
		}, DEFAULT_WAIT_TIME);

	};

	useEffect(clearTimeoutRefObserver, []);
	useEffect(searchResultsObserver, [value, searchCallback, setSearchResult]);
	useEffect(() => {
		setValue(searchValue);
	}, [searchValue]);

	if (SearchResultRowComponent === undefined || SearchResultRowComponent === null)
		return null;
	if (AutocompleteComponent === undefined || AutocompleteComponent === null)
		return null;
	if (setSearchResult === undefined || setSearchResult === null)
		return null;

	return (<AutocompleteComponent
		getItemValue={(item) => `${item.label}, ${item.sublabel.trim()}`}
		items={items}
		renderItem={(item, isHighlighted) => {
			if (item.label) {
				return <div className={styles.highlightSearch + " " + (isHighlighted ? styles.highlightSearchFocus : null)} style={{ width: "100%" }}>
					<SearchResultRowComponent item={item} />
				</div>;
			}

		}}
		value={value}
		onChange={(e) => {
			if (e.target === null)
				return;
			setValue(e.target.value);
			setSelectedValueCallback(null);
		}}
		onSelect={(val) => {
			setValue(val);
			const [namePart] = val.split(',');
			const selectedItem = items.find(item => item.label === namePart);
			setSelectedValueCallback(selectedItem);
		}}
		inputProps={{
			onKeyDown: () => {
				// This make it work the autocomplete events listeners
			}
		}}
		renderInput={(props) => inputElement({ ...props, isloading: '' + waitingSearchCallback, didtrysearch: '' + didTrySearch })}
		wrapperProps={{ className: styles.formControlDireccionContainer }}
		wrapperStyle={{ width: "100%" }}
		menuStyle={{
			position: 'absolute',
			left: 0,
			top: "auto",
			width: "100%",
			zIndex: 10
		}}
	></AutocompleteComponent>);
};