import React, { useState, useEffect, useRef } from 'react';
import { IcoChevron } from '../../../iconos';
import styles from './Buscador.module.scss';

export const OpcionBuscador = ({ id, clase, opcionesBuscador, setValueCallback, email, sessionId }) => {
	const [optionValue, setOptionValue] = useState(opcionesBuscador[0] ?? {});
	const [openMenuUser, setOpenMenuUser] = useState(false);
	const menuUserOn = (e) => {
		if(e !== undefined){
			e.preventDefault();
			e.stopPropagation();
		}
		
		setOpenMenuUser(true);
	}
	const menuUserOff = (e) => {
		if(e !== undefined){
			e.preventDefault();
			e.stopPropagation();
		}
		
		setOpenMenuUser(false);
	}
	const btnMenu = useRef();

	useEffect(() => {

		const closeDropdown = e => {
			if (e.target !== btnMenu.current) {
				menuUserOff();
			}
		};

		document.body.addEventListener('click', closeDropdown);

		return () => document.body.removeEventListener('click', closeDropdown);
	}, []);

	useEffect(() => {
		if (optionValue !== null || optionValue !== undefined)
			setValueCallback(optionValue);
	}, [optionValue, setValueCallback]);

	const cambiarOpcion = (opcion) => {
		setOptionValue(opcion);
		menuUserOff();
	};

	const _HandlerAbrirDropdown = () => {
		return openMenuUser ? 'dropdown show' : 'dropdown';
	};
	const _HandlerContenidoDropdown = () => {
		return openMenuUser ?
			`dropdown-menu dropdown-menu-right show ${styles.dropdownMenu} ${styles.contentDropDownMenuCustom}` :
			`dropdown-menu dropdown-menu-right ${styles.dropdownMenu}`;
	};

	return (
		<div className={`contenedorDropdown ${styles.contentGroupDropdown}`}>
			<div id="contDropdownUser" className={`${_HandlerAbrirDropdown()} ${styles.dropdownCustom}`} >
				<button ref={btnMenu} onClick={(e) => { !openMenuUser ? menuUserOn(e) : menuUserOff(e); }} className={`btn ${styles.btn} ${clase}`} type="button" id={`${id}-btnMenuUser`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<span className={`${styles.item} ${styles.itemFullWith}`}>{optionValue.tipo} </span>
					<span className={`${styles.chevron}`}><IcoChevron color='#343A40' /></span>
				</button>

				<div id={id} className={_HandlerContenidoDropdown()} aria-labelledby="btnMenuUser">
					{
						opcionesBuscador.map((item) => (
							<a key={item.tipo} href="#" className='dropdown-item' onClick={() => cambiarOpcion(item)}
								data-email={email} data-event={"click"} data-event-key={id}
								data-location={"home"}
								data-session-id={sessionId}
								data-event-value={item.tipo}>{item.tipo}</a>
						))
					}
				</div>
			</div>
		</div>
	);
};

export default OpcionBuscador;