import { Card } from '../../card/view/card';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import styles from './NewsFeed.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import { useEffect, useState } from 'react';


// eslint-disable-next-line react/display-name
const renderCard = (btnUrl, cardsClickables) => (entrada, i) => {

	return <SwiperSlide key={i}>
		<Card
			imgCard={entrada.img.src}
			alt={entrada.img.alt}
			imgClass={styles.imgCard}
			tituloCard={entrada.titulo}
			descripcion={entrada.subtitulo}
			descripcionClassExtra={styles.contenedorDescripcion}
			cardCssExtra={btnUrl ? styles.contenedorCard : ""}
			url={entrada.url}
			esClickable={cardsClickables}
			bodyClass={styles.cardBody}
		/>
	</SwiperSlide>;
};

export const NewsFeed = ({ listaNoticias, grupoTitulo, grupoTituloMobil, cardsClickables, btnUrl, btnTexto, getFeed }) => {
	const [listaNoticiasState, setListaNoticiasState] = useState(listaNoticias);
	const [status, setStatus] = useState(0);
	const setupNewsFeed = () => {
		if (status === 1) return;
		if ((listaNoticiasState === undefined || listaNoticiasState === null || listaNoticiasState.length === 0) && getFeed !== null) {
			setStatus(1);
			getFeed(1).
				then(news => setListaNoticiasState(news));

		}
	};
	useEffect(setupNewsFeed, [getFeed, listaNoticiasState, status]);

	if (listaNoticiasState === undefined || listaNoticiasState === null || listaNoticiasState.length === 0) return null;
	if (grupoTitulo && !grupoTituloMobil) { grupoTituloMobil = grupoTitulo; }

	return <>
		<div className={`container ${styles.contenedorNoticias}`}>
			{grupoTitulo && (<h2 className={`d-none d-none d-sm-block ${styles.ttNoticias}`}> {grupoTitulo}</h2>)}
			{grupoTituloMobil && (<h2 className={`d-block d-sm-none ${styles.ttNoticias}`}> {grupoTituloMobil}</h2>)}

			<Swiper
				modules={[Pagination]}
				className='customSliderCard'
				spaceBetween={10}
				slidesPerView={1.15}
				pagination={{ clickable: true }}
				breakpoints={{
					769: {
						slidesPerView: 3,
					},
				}}
			>
				{listaNoticiasState.map(renderCard(btnUrl, cardsClickables))}
			</Swiper>
			{btnUrl &&
				<div className={styles.contenedorBtnBlogs}>
					<a className={styles.btnOutline} href={btnUrl}> {btnTexto ? btnTexto : `Conocer más`} </a>
				</div>
			}
		</div>
	</>;
};

export default NewsFeed;