import React from 'react';

export const OnboardingCard = ({
	tarjetaClassName,
	tarjetaContenidoClassName,
	tituloTarjetaClassName,
	bodyTarjetaClassName,
	tituloTarjeta,
	bodyTarjeta,
	children,
	contenedorIcono
}) => {
	return (
		(
			tarjetaClassName,
			tarjetaContenidoClassName,
			tituloTarjetaClassName,
			bodyTarjetaClassName,
			tituloTarjeta,
			bodyTarjeta,
			children,
			contenedorIcono
		) ?
			<div className={tarjetaClassName}>
				<div className={contenedorIcono}>{children}</div>
				<div className={tarjetaContenidoClassName}>
					<h2 className={tituloTarjetaClassName}>{tituloTarjeta}</h2>
					<h3 className={bodyTarjetaClassName}>{
						bodyTarjeta.map((texto, indice) => <p key={indice}>{texto}</p>)}</h3>
				</div>
			</div>
			: <></>);
};
