import { getDataAttributes } from 'modules';
import styles from './Card.module.scss';

export const Card = (props) => {
	const {
		contenedorImgCardEmpresaClass,
		imgCardEmpresa,
		tamanoImagen,
		contenedorProductosEmpresaClass,
		ttSeccion,
		tipoCliente,
		ttTipoCliente,
		bodyClass,
		tituloCard,
		descripcion,
		descripcionClass,
		descripcionClassExtra,
		button,
		buttonText,
		cardCssExtra,
		claseButton,
		url,
		seccion,
		caracteristicas,
		contenedorCaracteristicasClass,
		contenedorttCaracteristicasClass,
		contenedorIconoClass,
		children,
		imgCard,
		alt,
		imgClass,
		HeaderComponent,
		BodyComponent,
		esClickable
	} = props;
	const dataAttributes = getDataAttributes(props);
	const HeaderBlock = () => (
		<>
			{
				seccion &&
				<div className={contenedorProductosEmpresaClass}>
					<h2 className={ttSeccion}>{seccion}</h2>
					<div className={contenedorImgCardEmpresaClass}>
						<img src={imgCardEmpresa} className={tamanoImagen} />
					</div>
				</div>
			}
		</>);

	const ImgBlock = () => (
		<>
			{imgCard && <img src={imgCard} className={`card-img-top ${styles.imgCard} ${imgClass ?? ""}`} alt={alt} title={alt} />}
		</>
	);

	const ClientTypeBlock = () => (
		<>
			{tipoCliente &&
			<div className={styles.cTipoCliente}>
				{tipoCliente.map((nombre, index) => {
					return <>
						<div key={index} className={nombre === 'Inmobiliarias' ? styles.contenedorInmobiliarias : styles.contenedorCorredoras}>
							<h2 className={styles.ttTipoCliente}>{nombre}</h2>
						</div>
					</>
				})}
			</div>}
		</>
	);

	const CardBodyBlock = () => (
		<div className={`card-body ${styles.cardBody} ${bodyClass ? bodyClass : ""}`}>
			{tituloCard && <h2 className={styles.ttCard}>{tituloCard}</h2>}
			{descripcion && <h3 className={descripcionClassExtra ? ((descripcionClass ? descripcionClass : styles.descripcionCard) + " " + descripcionClassExtra) : (descripcionClass ? descripcionClass : styles.descripcionCard)}>{descripcion}</h3>}
			<SpecsListBlock />
			<ButtonBlock />
		</div>
	);

	const SpecsListBlock = () => (
		<>
			{caracteristicas && <div className={contenedorCaracteristicasClass}>
				{caracteristicas.map((texto, indice) =>
					<div className={contenedorttCaracteristicasClass} key={indice}>
						<div className={contenedorIconoClass}>{children}</div>
						<p>{texto}</p>
					</div>)}
			</div>
			}
		</>
	);

	const ButtonBlock = () => (
		<>
			{button &&

				<div className={`${styles.botonera} productoCardButton`}>
					<a href={url} className={[`${claseButton}`]} {...dataAttributes} data-titulo={tituloCard} data-producto={seccion} data-categoria={tipoCliente}>{buttonText}</a>
				</div>
			}
		</>
	);

	return (
		<div className={`card ${cardCssExtra ? (styles.card + " " + cardCssExtra) : styles.card}`} >
			{esClickable ? <a href={url} className={styles.clickZone}></a> : ""}
			{HeaderComponent ? <HeaderComponent />
				:
				<>
					<HeaderBlock />
					<ImgBlock />
				</>
			}

			{BodyComponent ? <div className='card-body'><BodyComponent /></div>
				:
				<>
					<ClientTypeBlock />
					<CardBodyBlock />
				</>
			}

		</div>
	);
};
export default Card;