import React from 'react';
import { IcoGps, IcoCorazon, IcoBaño, IcoCama, IcoSuperficie } from '../../../iconos';
import styles from './CardPropiedad.module.scss';

const getIdProjectFromUrl = (url) => {
	if (url === null || url === undefined) return null;
	let pathname = null;
	try {
		pathname = new URL(url).pathname;
	} catch (ex) {
		return null;
	}
	return pathname.substring(pathname.lastIndexOf('/') + 1);
};

export const CardPropiedad = ({
	titulo,
	comuna,
	imagenPrincipal,
	imagenInmobiliaria,
	urlFicha,
	esDestacada,
	esFavorita,
	tipoPropiedad,
	tipoOperacion,
	precios,
	superficie,
	dormitorios,
	bannos,
	userId
}) => {
	const idProyecto = getIdProjectFromUrl(urlFicha);
	const cantidadCaracteristica = (caracteristica) => caracteristica && caracteristica.length > 1 && `a ${caracteristica[1]}`;
	return (
		<div id="hp-contenedor-card-propiedades-destacadas" className={styles.contenedorCard}>
			<div className={`card ${styles.card}`}>
				<a className={styles.cardFoto} href={urlFicha}>
					<img data-id-usuario={userId} data-id-proyecto={idProyecto} data-comuna={comuna} data-precio={`${precios[0].prefix} ${precios[0].value}`} data-cantidad-dormitorios={dormitorios} data-cantidad-banos={bannos} className='' src={imagenPrincipal.src} alt={imagenPrincipal.alt} />
					{esDestacada && <div className={styles.destacado}>Destacado</div>}
					{esFavorita && <div id="hp-boton-favorita" data-id-usuario={userId} data-id-proyecto={idProyecto} data-comuna={comuna} data-region={null} data-precio={`${precios[0].prefix} ${precios[0].value}`} data-cantidad-dormitorios={dormitorios} data-cantidad-banos={bannos} className={styles.like}><IcoCorazon /> </div>}
				</a>
				<a href={urlFicha}>
					<div className='card-body'>
						<div className={styles.contenedorTTimg}>
							<div className={styles.infoPrincipal}>
								<p className={`${styles.comuna} text-truncate`}><IcoGps /> {comuna}</p>
								<div className={styles.infoTipo}>
									<p className={styles.txtTipo}>{tipoPropiedad} |<span className={styles.txtOperacion}> {tipoOperacion}</span></p>
								</div>
							</div>
							<div className={styles.contenedorLogo}>
								<img className={styles.logoCard} src={imagenInmobiliaria.src} alt={imagenInmobiliaria.alt} />
							</div>
						</div>

						<h5 className={`${styles.nombre}`}>{titulo}</h5>

						<div className={styles.separador}></div>
						<div className=''>
							<ul className={`${styles.programa}`}>
								{dormitorios && dormitorios.length > 0 && <li className={styles.dormitorios}><IcoCama /><p>{dormitorios[0]} {cantidadCaracteristica(dormitorios)}</p></li>}
								{bannos && bannos.length > 0 && <li className={styles.banos}><IcoBaño /><p>{bannos[0]} {cantidadCaracteristica(bannos)} </p></li>}
								<li className={styles.superficieUtil}> <IcoSuperficie /> <p className={styles.superficie}>{superficie[0]} {cantidadCaracteristica(superficie)} m2</p></li>
							</ul>
						</div>
						<div className={styles.precios}>
							<p className={styles.txtLabel}>{tipoOperacion.toLowerCase() === 'venta usado' || tipoOperacion.toLowerCase() === 'arriendo' ? 'Precio' : 'Desde'}</p>
							<p className={styles.txtPrecioUf}>{tipoOperacion.toLowerCase() === 'arriendo' ? (precios && `${precios[1]?.prefix} ${precios[1]?.value}`) : (precios && `${precios[0]?.prefix} ${precios[0]?.value}`)} </p>
						</div>

						<div>
							<a data-id-usuario={userId} data-id-proyecto={idProyecto} data-comuna={comuna} data-precio={`${precios[0].prefix} ${precios[0].value}`} data-cantidad-dormitorios={dormitorios} data-cantidad-banos={bannos} className={styles.btnCta} href={urlFicha}>Ver más</a>
						</div>
					</div>
				</a>
			</div>

		</div>
	);
};

export default CardPropiedad;
