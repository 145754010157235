import React, { useState } from 'react';
import styles from './PreFooter.module.scss';

const PreFooter = ({ dataPreFooter, isMobile }) => {
	const [operacionIndex, setOperacionIndex] = useState(0);
	const [propiedadIndex, setPropiedadIndex] = useState(0);

	const handleChangeOperacion = (event) => {
		setOperacionIndex(+event.target.value);
		setPropiedadIndex(0)
	};

	const handleChangePropiedad = (event) => {
		setPropiedadIndex(+event.target.value);
	};
	return (
		<div className={`container ${styles.contenedorPrincipal}`}>
			{isMobile ? <h3>¡Encuentra tu propiedad ideal!</h3> : <h2>¡Encuentra tu propiedad ideal!</h2>}
			<div value={operacionIndex} aria-label="tipos de operación" className={`${isMobile ? styles.contenedorOperacion : ""}`}>
				{dataPreFooter.map((operacion, index) => (
					<button key={index} type='button' value={index} onClick={handleChangeOperacion} className={`${styles.tabHeader} ${styles.primaryTabHeader} ${operacionIndex === +index ? styles.activeButton : ""}`}>{operacion.name}</button>
				))}
			</div>

			{dataPreFooter.map((operacion, primaryLevelIndex) => {
				return (<div key={primaryLevelIndex} value={operacionIndex} index={primaryLevelIndex} className={operacionIndex === +primaryLevelIndex ? "" : styles.hide}>
					<div value={propiedadIndex} aria-label="tipos de propiedad" className={`${styles.contenedorPropiedad}`} >
						{operacion.collection.map((propiedad, index) => (
							(isMobile) ?
								<div className={`${operacionIndex === +index ? styles.activeSelect : styles.hiddenSelect}`}>
									<p className={styles.nativeSelectLabel}>Tipo de propiedad</p>
									<select
										aria-label="tipos de propiedad"
										onChange={handleChangePropiedad} ß
										className={`${styles.nativeSelect}`}
									>
										{operacion.collection.map((propiedad, index) => (
											<option key={index} value={index}>
												{propiedad.name}
											</option>
										))}
									</select>
									<div className={`${styles.chevron} ${styles.chevronArrowDown}`}></div>
								</div>
								:
								<button key={index} type='button' value={index} onClick={handleChangePropiedad} className={`${styles.tabHeader} ${styles.secondaryTabHeader} ${propiedadIndex === +index ? styles.activeButton : ""}`}>{propiedad.name}</button>
						))}
					</div>
					{operacion.collection.map((propiedad, index) => {
						return (
							<div key={index} index={index} className={`${styles.preFooterLinkContainer} ${operacionIndex === +primaryLevelIndex && propiedadIndex === +index ? "" : styles.hide} ${isMobile ? styles.preFooterLinkContainerMobile : ""}`} >
								{propiedad.collection.map((column, index) => {
									return <div key={index} className={styles.preFooterLink}>
										{column.map((row, index) => {
											return (
												(isMobile) ?
													<div key={index} className={styles.hidden}>
														<button type="button" className={styles.collapsible} onClick={(e) => e.target.parentElement.classList.contains(styles.hidden) ? e.target.parentElement.classList.remove(styles.hidden) : e.target.parentElement.classList.add(styles.hidden)} >{row.name}</button>
														<div className={`${styles.chevron} ${styles.chevronArrowUp}`}></div>
														<div className={`${styles.chevron} ${styles.chevronArrowDown}`}></div>
														<ul className={styles.contenedorUl}>
															{row.collection.map((comuna, index) => (
																<li key={index} className={styles.noBulletPoints}>
																	<a href={comuna.url}>{comuna.nombre}</a>
																</li>
															))}
														</ul>
													</div>
													:
													<div key={index}>
														<p variant="p" className={styles.labelRegion}>{row.name}</p>
														<ul className={styles.contenedorUl}>
															{row.collection.map((comuna, index) => (
																<li key={index} className={styles.noBulletPoints}>
																	<a href={comuna.url}>{comuna.nombre}</a>
																</li>
															))}
														</ul>
													</div>
											)
										})}
									</div>
								})}
							</div>
						)
					})}
				</div>)
			})}
		</div>
	);
};

export default PreFooter;