import styles from './Publicar.module.scss';
import { IcoChevron } from '../../../iconos';

export const Publicar = ({ url, user }) => {
	const { id: userId } = user ?? {};
	return <>
		<div className='container'>
			<div className={styles.contenedorPublicar}>
				<div className={styles.contenedorTitulo}>
					<p>Publica tu propiedad con nosotros <span>¡Es simple y fácil!</span><span className={styles.chevrones}><IcoChevron /><IcoChevron /></span>  </p>
				</div>
				<div className={styles.contenedorBajada}>
					<p>Regístrate y pública con nosotros para que tu anuncio sea visto por miles de personas diariamente. </p>
				</div>
				<a id="hp-btn-publicar" data-id-usuario={userId} data-ubicacion-boton='landing-publicar' className={styles.btnCta} href={url}>Publica aquí</a>
			</div>
		</div>
	</>;
};
export default Publicar;