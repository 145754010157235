import styles from './Productos.module.scss';
import Card from '../../card/view/card';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import { getDataAttributes } from 'modules';

const renderCardWithDataAttr = (dataAttr) => (cardData, i) => renderCard({ ...dataAttr, ...cardData }, i);

const renderCard = (entrada, i) =>
	<SwiperSlide key={i}>
		<Card
			imgCard={entrada.img.src}
			alt={entrada.img.alt}
			tituloCard={entrada.titulo}
			descripcion={entrada.descripcion}
			button={true}
			buttonText={entrada.buttons[0].text}
			claseButton={entrada.buttons[0].class}
			url={entrada.buttons[0].url}
			bodyClass={styles.cardBody}
			{...getDataAttributes(entrada)}
		/>
	</SwiperSlide>;

export const Productos = ({ listaProductos, user }) => {
	if (listaProductos === undefined || listaProductos === null || listaProductos.length === 0) return null;
	const { id: userId } = user ?? {};
	return <>
		<div className={`container ${styles.contenedorProductos}`}>
			<h2 className={styles.ttProductos}>Conoce nuestros productos</h2>
			<p className={styles.subttProducto}>¡En TOCTOC te acompañamos en cada paso para encontrar tu nuevo hogar!</p>
			<Swiper
				modules={[Pagination]}
				className='customSliderCard'
				spaceBetween={10}
				slidesPerView={1.1}
				pagination={{ clickable: true }}
				breakpoints={{
					769: {
						slidesPerView: 4,
					},
				}}
			>
				{listaProductos.map(renderCardWithDataAttr({ ["data-id-usuario"]: userId }))}
			</Swiper>
		</div>

	</>;
};