import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import styles from './Banner.module.scss';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/navigation";
import Banner from './banner';
import { IcoChevron } from '../../../iconos';

const renderBannerWithUser = (idUser) => (bannerItem, key) => renderBanner({ ...bannerItem, idUser }, key);

const renderBanner = (banner, i) => {
	const { img, idUser } = banner;
	const { src, alt, url } = img;
	return (
		<SwiperSlide key={i}>
			<Banner imagen={src} alt={alt} url={url} idUser={idUser} />
		</SwiperSlide>
	);
};


export const Banners = ({ listaBanners, user }) => {
	const { id: idUser } = user ?? {};
	const nombreBanners = listaBanners.map((banner) => banner.img.alt);

	const swiperRef = useRef(null);
	if (listaBanners === undefined || listaBanners === null || listaBanners.length === 0) return null;
	return (
		<div>
			<div id="hp-contenedor-carrusel" data-id-usuario={idUser} data-nombre-banners={nombreBanners} className={styles.contenedorCarrusel}>
				{listaBanners.length > 2 ? <a id="previousButton" data-id-usuario={idUser} data-nombre-banners={nombreBanners} onClick={() => swiperRef.current.swiper.slidePrev()} className={styles.arrowPrev} > <IcoChevron /> </a> : null}
				<div className={`container ${styles.contenedorSlide}`}>
					<Swiper
						ref={swiperRef}
						modules={[Autoplay, Pagination]}
						slidesPerView={1}
						pagination={{
							clickable: true, renderBullet: function () {
								return `<span data-id-usuario="${idUser}" data-nombre-banners="${nombreBanners}" class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>`;
							}
						}}
						onSlideChange={() => { }}
						onSwiper={(swiper) => { }}
						autoplay={{
							delay: 5000,
							disableOnInteraction: false,
						}}
						breakpoints={{
							375: {
								slidesPerView: 1.1,
								spaceBetween: 7,
							},
							769: {
								slidesPerView: 1,
								spaceBetween: 10,
							},
							992: {
								slidesPerView: 2,
								spaceBetween: 10,
								slidesPerGroup:2,
							},
						}}
					>
						{listaBanners.map(renderBannerWithUser(idUser))}
					</Swiper >
				</div>
				{listaBanners.length > 2 ? <a id="nextButton" data-id-usuario={idUser} data-nombre-banners={nombreBanners} onClick={() => swiperRef.current.swiper.slideNext()} className={styles.arrowNext} ><IcoChevron /> </a> : null}
			</div>
		</div>
	);
};