import React, { useState, useEffect, useRef } from 'react';
import CardPropiedad from '../../card/view/cardPropiedad';
import styles from './Destacado.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import useWindowSize from '../../../public/utils/useWindowsSize';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/navigation";

const opcionesTabs = [
	{
		id: 1,
		titulo: 'Proyectos nuevos en venta'
	},
	{
		id: 2,
		titulo: 'Propiedades usadas en venta'
	},
	{
		id: 3,
		titulo: 'Propiedades en arriendo'
	}
];


const breakpointsDesktop = {
	568: {
		slidesPerView: 1.5,
		spaceBetween: 10,
	},
	769: {
		slidesPerView: 2.5,
		spaceBetween: 10,
	},
	1024: {
		slidesPerView: 3.1,
		spaceBetween: 10,
	},
	1200: {
		slidesPerView: 3.5,
		spaceBetween: 10,
	},
	1440: {
		slidesPerView: 4,
		spaceBetween: 10,
	},
};

const renderCardWithUser = (userId) => (card) => renderCard({ ...card, userId });

const renderCard = (card) =>
	<SwiperSlide >
		<CardPropiedad
			titulo={card.titulo}
			comuna={card.comuna}
			imagenPrincipal={card.imagenPrincipal}
			imagenInmobiliaria={card.imagenInmobiliaria}
			urlFicha={card.urlFicha}
			esDestacada={card.esDestacada}
			esFavorita={card.esFavorita}
			tipoPropiedad={card.tipoPropiedad}
			tipoOperacion={card.tipoOperacion}
			precios={card.precios}
			superficie={card.superficie}
			dormitorios={card.dormitorios}
			bannos={card.bannos}
			userId={card.userId}
		/>
	</SwiperSlide>;

export const Destacado = ({ listaDestacados, user }) => {
	const { id: userId } = user ?? {};
	const [activo, setActivo] = useState(1);
	const [listaActiva, setListaActiva] = useState([]);
	const pixelSeo = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
	const colorActivo = (id, activo) => {
		return activo === id ? `${styles.tabActivo}` : `${styles.tab}`;
	};
	useEffect(() => {
		setListaActiva(listaDestacados.filter((card) => card.tipoOperacion === activo));
	}, [activo]);

	const anchoAlto = useWindowSize();
	const swiperRef = useRef(null);
	const versionTabs = () => {
		return anchoAlto.width <= 767 ? <Swiper
			slidesPerView={1.2}
			spaceBetween={1}
			breakpoints={{
				375: {
					slidesPerView: 1.4,
					spaceBetween: 5,
				},
				420: {
					slidesPerView: 1.7,
					spaceBetween: 5,
				},
				769: {
					slidesPerView: 3,
					spaceBetween: 2,
				},
			}}
		>
			{opcionesTabs.map((tab) => {
				return (
					<SwiperSlide key={`tabM-${tab.id}`}>
						<h2 id="hp-tipo-propiedad-destacada" data-id-usuario={userId} data-tipo-propiedad={tab.titulo} className={colorActivo(tab.id, activo)} onClick={() => setActivo(tab.id)}>{tab.titulo}</h2>
					</SwiperSlide>
				);
			})}
		</Swiper >
			:
			<div className={styles.containerDesktop}>
				{opcionesTabs.map((tab) => {
					return (
						<h2 id="hp-tipo-propiedad-destacada" data-id-usuario={userId} data-tipo-propiedad={tab.titulo} key={`tab-${tab.id}`} className={colorActivo(tab.id, activo)} onClick={() => setActivo(tab.id)}>{tab.titulo}</h2>
					);
				})}
			</div>;
	};

	useEffect(() => {
		swiperRef.current.swiper.slideTo(0)
	}, [activo])

	return (
		<div className={styles.contenedorDestacado}>
			<div className=''>
				<h1 className={styles.ttDestacado}>Propiedades destacadas <img src={pixelSeo} alt='Casas y departamentos en arriendo y en venta'></img></h1>
				<p className={styles.parrafoDestacado}>¿Estás pensando en comprar o arrendar? Revisa las propiedades que seleccionamos para ti</p>
				<div className={`container  ${styles.containerTabs}`}>
					{versionTabs()}
				</div>
				<div id="hp-contenedor-card-destacado" data-id-usuario={userId} className={`container ${styles.sliderCard}`} >
					<Swiper
						ref={swiperRef}
						pagination={{ clickable: true }}
						navigation={{ prevEl: "#hp-contenedor-card-destacado .swiper-button-prev", nextEl: "#hp-contenedor-card-destacado .swiper-button-next" }}
						modules={[Pagination, Navigation]}
						slidesPerView={1.1}
						spaceBetween={10}
						initialSlide={0}
						breakpoints={breakpointsDesktop}
					>
						<div className="swiper-button-prev" data-id-usuario={userId} onClick={() => swiperRef.current.swiper.slidePrev()} ></div>
						<div className="swiper-button-next" data-id-usuario={userId} onClick={() => swiperRef.current.swiper.slideNext()} ></div>
						{listaActiva.map((item, i) => {
							return (
								item.lista.map(renderCardWithUser(userId))
							);
						})}
					</Swiper >
				</div>
			</div>
		</div>
	);
};

export default Destacado;
