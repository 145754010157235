import styles from './Banner.module.scss';

export const Banner = ({ imagen, alt, url, idUser }) => {
	if (imagen === undefined) return null;
	return (
		<div id="hp-contenedor-img-banner" className={styles.contenedorBanner}>
			<a href={url} alt={alt}>
				<div data-id-usuario={idUser} data-id-proyecto={null} data-nombre-banner={alt} className={styles.banner} style={{
					backgroundImage: `url(${imagen})`
				}}>
				</div>
			</a>
		</div>
	);
};
export default Banner;