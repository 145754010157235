import { IcoMapPin } from "iconos";
import styles from './SearchResultRow.module.scss';

export const SearchResultRow = ({ item }) => {
	if (item === null || item === undefined)
		return null;
	const { label, sublabel, tipo, id } = item;

	return <div data-id={id} className={styles.searchResultRow}>
		<div className={styles.searchResultRowIcon}>
			<IcoMapPin></IcoMapPin>
		</div>
		<div className={styles.searchResultRowLabel}>
			<span>{label}</span>
			<label>{tipo}, {sublabel}</label>
		</div>
	</div>;
};