import React from 'react';
import styles from './Onboarding.module.scss';
import { IcoMegafono, IcoBinoculares, IcoBurbujaChat, IcoSonrisa } from '../../../iconos';
import { OnboardingCard } from './OnboardingCard';
import { Icon } from '@toctoc/design-system';

export const Onboarding = () => {
	const { contenidoOnboarding } = {
		contenidoOnboarding: [{
			titulo: 'Publicar',
			cuerpo: ['¿Necesitas vender o arrendar tu propiedad?', 'Sólo regístrate y publica con nosotros'],
			icono: <Icon color="#3200C1" name="PhoneToPost" size={56} strokeWith="0.7"/>
		}, {
			titulo: 'Encontrar',
			cuerpo: ['Encuentra y selecciona la propiedad que necesitas.'],
			icono: <Icon color="#3200C1" name="Binoculars" size={56} strokeWith="0.7"/>
		}, {
			titulo: 'Contactar',
			cuerpo: ['¿Te interesó alguna propiedad?', 'Contacta al publicador y gestiona una vista'],
			icono: <Icon color="#3200C1" name="ChatCircle" size={56} strokeWith="0.7"/>
		}, {
			titulo: 'Decidir',
			cuerpo: ['Te entregamos la mejor información para tomar decisiones ¡Cierra el trato y disfruta de tu nuevo hogar!'],
			icono: <Icon color="#3200C1" name="Smiley" size={56} strokeWith="0.7"/>
		}]
	};
	return (
		<div className={`container ${styles.onboarding}`} title="mainContainer">
			<h2 className={styles.ttOnboarding}>En TOCTOC te ayudamos a</h2>
			<div className={styles.contenedorOnboarding}>
				<div className={styles.imagen} >
				</div>
				<div className={styles.contenedorInfo}>
					{contenidoOnboarding.map((tarjeta, indice) =>
						<OnboardingCard
							key={`onboardingCard-${indice}`}
							tarjetaClassName={styles.contenedorItem}
							tarjetaContenidoClassName={styles.contenido}
							tituloTarjetaClassName={styles.ttItem}
							bodyTarjetaClassName={styles.contenidoOnb}
							tituloTarjeta={tarjeta.titulo}
							bodyTarjeta={tarjeta.cuerpo}
							contenedorIcono={styles.contenedorIcono}
						>{tarjeta.icono}</OnboardingCard>
					)}
				</div>
			</div>
		</div>
	);
};

export default Onboarding;